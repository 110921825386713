import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as Icon from 'react-bootstrap-icons';
import {useNavigate, useLocation} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import BreadcrumbList from '../../components/common/BreadcrumbList';
import constants from '../../helpers/en';
import { requestHeader } from "../../helpers/CommonUtils";
const axios = require('axios');

// Schema for yup
const validationSchema = Yup.object().shape({
  productName: Yup.string()
  .min(15, "*Name can't be less than 15 characters")
  .max(50, "*Name can't be longer than 50 characters")
  .required("*Name is required"),
  productDescription: Yup.string()
  .max(500, "*Description can't be longer than 500 characters")
  .required("*Description is required"),
  sku: Yup.string()
  .required("*SKU is required"),
  productStatus: Yup.string()
  .required("*Product Status is required"),
  activeDate: Yup.date()
  .required("*Activate Date is required"),
  features: Yup.array()
  .required("*Features are required"),
});

function EditProduct() {

  const breadList = [
    {url: "/products", title: constants.PRODUCT_PAGE_TITLE},
    {url: "/editProducts", title: constants.EDIT_PRODUCT_PAGE_TITLE}
  ];

  const [showUserMsg, setShowUserMsg] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [serviceFeatures, setServiceFeatures] = useState([]);
  const [serviceData, setServiceData] = useState({});
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const sku = query.get('sku');

  {/* Show/Hide features dropdown Modal */}
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  {/* Handle change value of features */}
  const handleFeaturesChange = selectedOption => {
    setSelectedOption( selectedOption );
    {/*console.log(`Option selected:`, selectedOption);*/}
  };

  //=== Fetch Service Product And Features
  useEffect(() => {
    fetchData();
  }, []); //This will run only once

  const fetchData = async () => {

      try {
        //=== Get product details to edit
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/products/${sku}`, requestHeader()).then(res => {
          let response = res.data;
          if ( response.meta.code === 200 ){
            setServiceData(response.data);
            let features = response.data.features;

            //=== Get features list
            axios.get(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/features`, requestHeader()).then(res => {
              let response = res.data;
              if ( response.meta.code === 200 ){
                let defSelectedFeatures = [];
                if( features ){
                  features.forEach(function(item){
                    defSelectedFeatures.push({value: item.featureId, label: item.featureName});
                  });
                  setSelectedOption( defSelectedFeatures );
                }
                setServiceFeatures(response.data.features);
              } else{
                setUserMsg(response.data.userMsg);
              }
            });
          } else{
            setUserMsg(response.data.userMsg);
          }
        });
      } catch (error) {
        console.error(error);
        setUserMsg(error);
      }

  }

  const options = [];
  if( serviceFeatures.length > 0 ){
    serviceFeatures.forEach(function(item){
      options.push({value: item.featureId, label: item.featureName});
    });
  }

  let selectedFeatures = [];
  let pdtActDateFinal = "";
  //=== Active date
  if( serviceData && Object.keys(serviceData).length > 0 ){
    var pdtActDate = new Date(serviceData.activeDate);
    var year = pdtActDate.getFullYear();
    var month = ("0" + (pdtActDate.getMonth() + 1)).slice(-2);
    var day = ("0" + pdtActDate.getDate()).slice(-2);
    pdtActDateFinal = ( year +"-"+ month +"-"+ day);
  }

  selectedOption.forEach(function(item){
    selectedFeatures.push(item.label);
  });

  return (
    <div className="App">

      <Header/>

      <Container fluid>
        <Row className="mb-5">
           <Col md={2} lg={2} xl={2} xs={12} className="sidebar text-start" id="duclo-sidebar">
            <div className="sidebar-content" >

              <Sidebar/>

             </div>
           </Col>

           <Col md={10} lg={10} xl={10} xs={12}>
            {/* Page header start*/}
            <Container>

              <div className="page-header mt-4 mb-5">
                <div className="page-title">
                  {constants.EDIT_PRODUCT_PAGE_TITLE}
                </div>
                <Row xs={1} md={2} className="g-4">
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <BreadcrumbList list={breadList} />
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                  </Col>
                </Row>
              </div>

            </Container>
            {/* Page header end*/}

            {/* Main content start*/}
            <Container className="h-100">
              <section className="main-content-before">
                <Icon.InfoCircle height="26" width="26" />
              </section>
              <section className="main-content">

               <Row className="g-4">

                {(serviceData !== {} &&  serviceData !== undefined) && (
                  <Formik
                  enableReinitialize={true}
                  initialValues={{ sku: sku, productName: serviceData.productName, productDescription: serviceData.productDescription, productStatus: serviceData.productStatus, features: serviceData.features, activeDate: pdtActDateFinal, licenseId: serviceData.licenseId}}
                  // Hooks up our validationSchema to Formik
                  validationSchema={validationSchema}
                  onSubmit={(values, {setSubmitting, resetForm}) => {
                    // When button submits form and form is in the process of submitting, submit button is disabled
                    setSubmitting(true);

                    //=== Add features into the payload
                    let selectedFeaturesId = [];
                    selectedOption.forEach(function(item){
                      selectedFeaturesId.push(item.value);
                    });

                    if( selectedFeaturesId.length > 0 ){
                       values.features = selectedFeaturesId;
                    }

                    const yourDate = values.activeDate;
                    let yourFormattedDate = new Date(yourDate).getTime();
                    const updatedValues = { ...values, activeDate: yourFormattedDate }

                    // Simulate submitting to database, shows us values submitted, resets form
                    axios.put(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/products`, updatedValues, requestHeader()).then(res => {
                      let response = res.data;
                      if ( response.meta.code === 200 ){
                        navigate('/products', {state: {type: 'update', id: sku, name: values.productName} });
                      } else{
                        console.log('not submitted');
                        setUserMsg(response.data.userMsg);
                        setShowUserMsg(true);
                        setSubmitting(false);
                      }
                    });

                  }}
                  >
                  {( {values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3 mt-3" controlId="sku">
                         <Form.Label column sm={3}>
                           SKU
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Control
                           type="text"
                           name="sku"
                           value={values.sku}
                           disabled={true}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.sku && errors.sku}/>
                           {touched.sku && errors.sku ? (
                             <Form.Control.Feedback type="invalid">
                               {errors.sku}
                             </Form.Control.Feedback>
                           ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 mt-3" controlId="productName">
                         <Form.Label column sm={3}>
                           Product Name
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Control
                           type="text"
                           name="productName"
                           value={values.productName}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.productName && errors.productName}/>
                           {touched.productName && errors.productName ? (
                             <Form.Control.Feedback type="invalid">
                               {errors.productName}
                             </Form.Control.Feedback>
                           ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="productDescription">
                         <Form.Label column sm={3}>
                           Description
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Control
                           as="textarea"
                           rows={5}
                           value={values.productDescription}
                           name="productDescription"
                           value={values.productDescription}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.productDescription && errors.productDescription}/>
                           {touched.productDescription && errors.productDescription ? (
                             <Form.Control.Feedback type="invalid">
                               {errors.productDescription}
                             </Form.Control.Feedback>
                           ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                         <Form.Label column sm={3}>
                           Status
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Check
                             inline
                             label="Active"
                             name="productStatus"
                             type="radio"
                             value="Active"
                             checked={values.productStatus === "Active"}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             isInvalid={touched.productStatus && errors.productStatus}
                             checked={true}
                           />
                           {/*}<Form.Check
                             inline
                             label="Disabled"
                             name="productStatus"
                             type="radio"
                             value="Disabled"
                             checked={values.productStatus === "Disabled"}
                             onChange={handleChange}
                             onBlur={handleBlur}
                             isInvalid={touched.productStatus && errors.productStatus}
                           />*/}
                         </Col>
                         <Col sm={2}></Col>
                         {touched.productStatus && errors.productStatus ? (
                           <Form.Control.Feedback type="invalid">
                             {errors.productStatus}
                           </Form.Control.Feedback>
                         ): null}
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 mt-3" controlId="activeDate">
                         <Form.Label column sm={3}>
                           Activate Date
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Control
                           type="date"
                           name="activeDate"
                           placeholder="Activate Date"
                           min={new Date().toISOString().split('T')[0]}
                           value={values.activeDate}
                           disabled={true}
                           onChange={handleChange }
                           onBlur={handleBlur}
                           isInvalid={touched.activeDate && errors.activeDate}/>
                           {touched.activeDate && errors.activeDate ? (
                             <Form.Control.Feedback type="invalid">
                               {errors.activeDate}
                             </Form.Control.Feedback>
                           ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="features">
                         <Form.Label column sm={3}>
                           Features
                         </Form.Label>
                         <Col sm={3}>
                          {touched.features && errors.features ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.features}
                            </Form.Control.Feedback>
                          ): null}

                          <Button variant="primary" onClick={handleShow}>
                            <Icon.PlusCircle height="26" width="26"/> Add Features
                          </Button>

                          <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                              <Modal.Title>Select Features</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                               <Select
                                  closeMenuOnSelect={false}
                                  isMulti
                                  options={options}
                                  onChange={handleFeaturesChange}
                                  value={selectedOption}/>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>
                         </Col>
                         <Col sm={6}><span>{selectedFeatures.join(", ")}</span></Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 mt-5">
                          <Col sm={12}>
                            {showUserMsg &&
                              <Alert variant="danger" onClose={() => setShowUserMsg(false)} dismissible>
                                {userMsg}
                              </Alert>
                             }
                         </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 mt-5">
                          <Col sm={10}>
                             <Button variant="link">Cancel</Button>
                             |
                             <Button variant="link">Save as Draft</Button>
                          </Col>
                          <Col sm={2}>
                              <Button type="submit" className="gray-button" disabled={isSubmitting}>
                                Submit
                                {isSubmitting && (
                                  <>
                                    &nbsp;
                                    <Spinner animation="border" size="sm"></Spinner>
                                  </>
                                )}
                              </Button>
                          </Col>
                        </Form.Group>

                      </Form>
                   )}
                   </Formik>
                )}

               </Row>
               </section>
            </Container>
            {/* Main content end*/}

          </Col>

        </Row>
      </Container>

    </div>

  );
}

export default EditProduct;
