import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
/* Import CSS and Image */
import BrandLogo from '../assets/images/duclo_logo.png';
import MegatronLogo from '../assets/images/MegatronLogo.svg';
/* Import Keycloack required files */
import { useKeycloak } from "@react-keycloak/web";
/* Import required pages */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import constants from '../helpers/en';
import { AppDefaults } from '../helpers/enums';

function SignIn(props) {

  const { keycloak } = useKeycloak();

  {/* If logged in and someone tries to hit signin url redirect to the Dashboard. */}
  if(!!keycloak.authenticated){
    return <Navigate to="/dashboard" push={true} />
  }

  return (
    <div className="App">
      <header className="App-header">
        <Container className="h-100">
          <Row bsPrefix="d-flex justify-content-center align-items-center h-100">
            <Col md={6} lg={6} xl={7}>
              <img src={process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                              ? MegatronLogo
                              : BrandLogo} 
                className="mt-4 mb-4 logo" alt={process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                ? constants.PROJECT_MEGATRON_TITLE: constants.PROJECT_DUCLO_TITLE} />
              <div className="card ">
                <div className="card-body text-center">

                    <h5 className="mb-5">{process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? constants.SIGN_IN_PAGE_TITLE_MEGATRON: constants.SIGN_IN_PAGE_TITLE_DUCLO}</h5>

                    {!keycloak.authenticated && (
                      <button
                        type="button"
                        className="btn-primary sign_in_btn mb-5"
                        onClick={() => keycloak.login({redirectUri: process.env.REACT_APP_KEYCLOAK_LOGIN_REDIRECT_URL})}
                      >
                        {constants.LOGIN_BUTTON_CONTENT}
                      </button>
                    )}

                    {!!keycloak.authenticated && (
                      <button
                        type="button"
                        className="btn-primary"
                        onClick={() => keycloak.logout({redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL})}
                      >
                        Logout ({keycloak.tokenParsed.preferred_username})
                      </button>
                    )}

                    <p>{process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? constants.AGREES_MEGATRON_CONTENT : constants.AGREES_DUCLO_CONTENT} <strong>Privacy Policy</strong> and <strong>Terms of Services</strong> by signing in.</p>

                </div>
              </div>

              <p className="mt-3">
                Don't have an account? Ask your administrator to invite you into your organization.
                <br/>
                {constants.NO_ORGANIZATION} <strong>{constants.CREATE_ORGANIZATION}</strong>
              </p>

            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}

export default SignIn;
