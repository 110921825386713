import * as Icon from 'react-bootstrap-icons';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

function BreadcrumbList(props) {

  let routes = null;
  if (typeof props.list !== 'undefined' && props.list.length > 0) {
    let ttlRoutes = props.list.length;
    routes = props.list.map((route, i) => {

      let divider = null;
      if( ttlRoutes > i ){
        divider = (<FontAwesomeIcon icon={faAnglesRight} size="xs"/>);
      }

      return (
         <Breadcrumb.Item key={i} href={route.url}>
          {divider}
          {route.title}
         </Breadcrumb.Item>
      );

    });
  }

  return (
    <div className="duclo-breadcrumbs ">
      <Breadcrumb>
        <Breadcrumb.Item href="/">
          <Icon.House height="20" width="20"/>
        </Breadcrumb.Item>
        {routes}
      </Breadcrumb>
    </div>
  );

}

export default BreadcrumbList;
