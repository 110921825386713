import React, {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as Icon from 'react-bootstrap-icons';
import {useNavigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Header from '../../components/common/Header';
import Sidebar from '../../components/common/Sidebar';
import BreadcrumbList from '../../components/common/BreadcrumbList';
import constants from '../../helpers/en';
import { requestHeader } from "../../helpers/CommonUtils";
const axios = require('axios');

// Schema for yup
const validationSchema = Yup.object().shape({
  featureName: Yup.string()
  .min(15, "*Name can't be less than 15 characters")
  .max(50, "*Name can't be longer than 50 characters")
  .required("*Name is required")
  .matches(/^[a-zA-Z ]+$/, "Only alphabets are allowed."),
  featureDesc: Yup.string()
  .max(500, "*Description can't be longer than 500 characters")
  .required("*Description is required"),
  featureTypeId: Yup.string()
  .required("*Feature Type is required"),
  analyticsType: Yup.string()
  .when("featureTypeId", {
        is: "6162c13c-e31a-47c1-b4f9-47bfdca06131",
        then: Yup.string().required("*Analytics Type is required")
  }),
  durationInDays: Yup.string()
  .when("featureTypeId", {
        is: "2bec8686-be6d-48ff-8c50-69c2962d0de0",
        then: Yup.string().required("*Duration is required")
  })
  .when("featureTypeId", {
        is: "8c6ebea6-6c2a-4a66-a02d-aba1e73aff70",
        then: Yup.string().required("*Duration is required")
  })
  .when("featureTypeId", {
        is: "586b6640-bf58-410c-b6ec-a5237f3feb6d",
        then: Yup.string().required("*Duration is required")
  }),
  featureStatus: Yup.string()
  .required("*Last Name is required"),
});

function NewFeature() {

  const breadList = [
    {url: "/features", title: constants.FEATURE_PAGE_TITLE},
    {url: "/newFeatures", title: constants.NEW_FEATURE_PAGE_TITLE}
  ];

  const [userMsg, setUserMsg] = useState('');
  const [serviceFeatures, setServiceFeatures] = useState([]);
  const navigate = useNavigate();

  //=== Fetch Service Entitlement Types
  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/features`, requestHeader()).then(res => {
        let response = res.data;
        if ( response.meta.code === 200 ){
          setServiceFeatures(response.data.featureTypes);
        } else{
          setUserMsg(response.data.userMsg);
        }
      });
    } catch (error) {
      console.error(error);
      setUserMsg(error);
    }
  }, []); //This will run only once

  return (
    <div className="App">

      <Header/>

      <Container fluid>
        <Row className="mb-5">
          <Col md={2} lg={2} xl={2} xs={12} className="sidebar text-start" id="duclo-sidebar">
            <div className="sidebar-content" >

              <Sidebar/>

             </div>
           </Col>

           <Col md={10} lg={10} xl={10} xs={12}>
            {/* Page header start*/}
            <Container>

              <div className="page-header mt-4 mb-5">
                <div className="page-title">
                  {constants.NEW_FEATURE_PAGE_TITLE}
                </div>
                <Row xs={1} md={2} className="g-4">
                  <Col md={6} lg={6} xl={6} xs={12}>
                    <BreadcrumbList list={breadList} />
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="text-end">
                  </Col>
                </Row>
              </div>

            </Container>
            {/* Page header end*/}

            {/* Main content start*/}
            <Container className="h-100">
              <section className="main-content-before">
                <Icon.InfoCircle height="26" width="26" />
              </section>
              <section className="main-content">

               <Row className="g-4">

                 <Formik
                 initialValues={{ featureName:"", featureTypeId:"", featureDesc:"", featureStatus:"Active", analyticsType: "", durationInDays: "30" }}
                 // Hooks up our validationSchema to Formik
                 validationSchema={validationSchema}
                 onSubmit={(values, {setSubmitting, resetForm}) => {
                   // When button submits form and form is in the process of submitting, submit button is disabled
                   setSubmitting(true);

                   // Simulate submitting to database, shows us values submitted, resets form
                   axios.post(`${process.env.REACT_APP_API_BASE_URL}sysadmin/services/features`, values, requestHeader()).then(res => {
                     let response = res.data;
                     if ( response.meta.code === 200 ){
                       navigate('/features', {state: {type: 'add', id: response.data.entitlementId, name: values.featureName} });
                     } else{
                       console.log('not submitted');
                       setUserMsg(response.data.userMsg);
                       setSubmitting(false);
                     }
                   });

                 }}
                 >
                 {( {values,
                   errors,
                   touched,
                   handleChange,
                   handleBlur,
                   handleSubmit,
                   isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Row} className="mb-3 mt-3" controlId="featureName">
                       <Form.Label column sm={3}>
                         Name
                       </Form.Label>
                       <Col sm={7}>
                         <Form.Control
                         type="text"
                         name="featureName"
                         placeholder="15 to 50 characters long. Cannot contain special characters."
                         value={values.featureName}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         isInvalid={touched.featureName && errors.featureName}/>
                         {touched.featureName && errors.featureName ? (
                           <Form.Control.Feedback type="invalid">
                             {errors.featureName}
                           </Form.Control.Feedback>
                         ): null}
                       </Col>
                       <Col sm={2}></Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="featureDesc">
                       <Form.Label column sm={3}>
                         Description
                       </Form.Label>
                       <Col sm={7}>
                         <Form.Control
                         as="textarea"
                         rows={5}
                         value={values.featureDesc}
                         name="featureDesc"
                         value={values.featureDesc}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         isInvalid={touched.featureDesc && errors.featureDesc}/>
                         {touched.featureDesc && errors.featureDesc ? (
                           <Form.Control.Feedback type="invalid">
                             {errors.featureDesc}
                           </Form.Control.Feedback>
                         ): null}
                       </Col>
                       <Col sm={2}></Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3" controlId="featureTypeId">
                       <Form.Label column sm={3}>
                         Feature Type
                       </Form.Label>
                       <Col sm={7}>
                        <Form.Select
                        aria-label="Select Feature Type" defaultValue={values.featureTypeId} name="featureTypeId"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.featureTypeId && errors.featureTypeId}>
                          <option>Select One</option>
                          {serviceFeatures.map((item, i) => {
                              return(<option key={i} value={item.featureTypeId}>{item.featureType}</option>);
                          })}
                        </Form.Select>
                        {touched.featureTypeId && errors.featureTypeId ? (
                          <Form.Control.Feedback type="invalid">
                            {errors.featureTypeId}
                          </Form.Control.Feedback>
                        ): null}
                       </Col>
                       <Col sm={2}></Col>
                      </Form.Group>

                      {values.featureTypeId === "6162c13c-e31a-47c1-b4f9-47bfdca06131" && (
                        <Form.Group as={Row} className="mb-3 mt-3" controlId="analyticsType">
                         <Form.Label column sm={3}>
                           Analytics Type
                         </Form.Label>
                         <Col sm={7}>
                           <Form.Control
                           type="text"
                           name="analyticsType"
                           value={values.analyticsType}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.analyticsType && errors.analyticsType}/>
                           {touched.analyticsType && errors.analyticsType ? (
                             <Form.Control.Feedback type="invalid">
                               {errors.analyticsType}
                             </Form.Control.Feedback>
                           ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>
                      )}

                      {(values.featureTypeId === "2bec8686-be6d-48ff-8c50-69c2962d0de0"
                      || values.featureTypeId === "8c6ebea6-6c2a-4a66-a02d-aba1e73aff70"
                      || values.featureTypeId === "586b6640-bf58-410c-b6ec-a5237f3feb6d"
                      ) && (
                        <Form.Group as={Row} className="mb-3" controlId="durationInDays">
                         <Form.Label column sm={3}>
                           Duration Type
                         </Form.Label>
                         <Col sm={7}>
                          <Form.Select
                          aria-label="Select Duration Type" defaultValue={values.durationInDays} name="durationInDays"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.durationInDays && errors.durationInDays}>
                            <option>Select One</option>
                            <option value="30">30 Days</option>
                            <option value="60">60 Days</option>
                            <option value="90">90 Days</option>
                            <option value="365">1 Years</option>
                            <option value="730">2 Years</option>
                            <option value="1825">5 Years</option>
                            <option value="3650">10 Years</option>
                            <option value="-1">Unlimited</option>
                          </Form.Select>
                          {touched.durationInDays && errors.durationInDays ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.durationInDays}
                            </Form.Control.Feedback>
                          ): null}
                         </Col>
                         <Col sm={2}></Col>
                        </Form.Group>
                      )}

                      <Form.Group as={Row} className="mb-3">
                       <Form.Label column sm={3}>
                         Status
                       </Form.Label>
                       <Col sm={7}>
                         <Form.Check
                           inline
                           label="Active"
                           name="featureStatus"
                           type="radio"
                           value="Active"
                           checked={values.featureStatus === "Active"}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.featureStatus && errors.featureStatus}
                         />
                         <Form.Check
                           inline
                           label="Disabled"
                           name="featureStatus"
                           type="radio"
                           value="Disabled"
                           checked={values.featureStatus === "Disabled"}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           isInvalid={touched.featureStatus && errors.featureStatus}
                         />
                       </Col>
                       <Col sm={2}></Col>
                       {touched.featureStatus && errors.featureStatus ? (
                         <Form.Control.Feedback type="invalid">
                           {errors.featureStatus}
                         </Form.Control.Feedback>
                       ): null}
                      </Form.Group>

                      <Form.Group as={Row} className="mb-3 mt-5">
                        <Col sm={10}>
                           <Button variant="link">Cancel</Button>
                           |
                           <Button variant="link">Save as Draft</Button>
                        </Col>
                        <Col sm={2}>
                            <Button type="submit" className="gray-button" disabled={isSubmitting}>
                              Submit
                              {isSubmitting && (
                                <>
                                  &nbsp;
                                  <Spinner animation="border" size="sm"></Spinner>
                                </>
                              )}
                            </Button>
                        </Col>
                      </Form.Group>
                    </Form>
                  )}
                  </Formik>
               </Row>
               </section>
            </Container>
            {/* Main content end*/}

          </Col>

        </Row>
      </Container>

    </div>

  );
}

export default NewFeature;
