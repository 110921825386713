import axios from 'axios';
import { Navigate } from 'react-router';
import { useKeycloak } from "@react-keycloak/web";

export  const SetAuthToken = token => {
  const { keycloak } = useKeycloak();

   if (token) {
      /* Set authorizartion header or Axios if token is available */
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.headers.common['X-Tenant-Id'] = process.env.REACT_APP_X_TENANT_ID;
      axios.defaults.headers.common['X-App-Id'] = process.env.REACT_APP_X_APP_ID;
      /* Set event to handle the 401 error code when token gets expire  */
      axios.interceptors.response.use(response => {
           return response;
        }, error => {
          if (error.response.status === 401) {
            localStorage.setItem('authenticated', false);
            localStorage.setItem('authToken', null);
            keycloak.logout({redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL});
          }
          return error;
        });
   }
   else
       delete axios.defaults.headers.common["Authorization"];
}
