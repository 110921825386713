import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Icon from "react-bootstrap-icons";
import { useKeycloak } from "@react-keycloak/web";
import { useTable, useSortBy, usePagination } from "react-table";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import SiteSpinner from "../components/common/SiteSpinner";
import constants from "../helpers/en";
import Select from "react-select";
import { HiOutlineBan } from "react-icons/hi";
import { FaCheckCircle } from "react-icons/fa";
import { requestHeader } from "../helpers/CommonUtils";
const axios = require("axios");

function Dashboard() {
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const tokenParsed = keycloak.tokenParsed;
  const [tokenData, setTokenData] = useState(null);

  //=== Set token and load the partner account requests list
  useEffect(() => {
    if (token && !tokenData) {
      localStorage.setItem("vmsAuthToken", token);
      setTokenData(token);
      fetchDataWithLoader(token); //=== Fetch the partner account list
    }
  });

  const [onBoardTableData, setOnBoardTableData] = useState([]);
  const [manageApproval, setManageApproval] = useState([]);
  const [userMsg, setUserMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: constants.DASHBOARD_STATUS_TITLE_PENDING_APPROVAL,
    label: constants.DASHBOARD_STATUS_LABEL_PENDING_APPROVAL,
  });
  const options = [
    {
      value: constants.DASHBOARD_STATUS_TITLE_PENDING_APPROVAL,
      label: constants.DASHBOARD_STATUS_LABEL_PENDING_APPROVAL,
    },
    {
      value: constants.DASHBOARD_STATUS_TITLE_PENDING_ACTIVATION,
      label: constants.DASHBOARD_STATUS_LABEL_PENDING_ACTIVATION,
    },
    {
      value: constants.DASHBOARD_STATUS_TITLE_NOT_APPROVED,
      label: constants.DASHBOARD_STATUS_LABEL_NOT_APPROVED,
    },
  ];

  useEffect(() => {
    fetchDataWithLoader();
  }, [selectedOption]);

  const getStatus = (accountStatus) => {
    const status =
      accountStatus === constants.DASHBOARD_STATUS_TITLE_PENDING_APPROVAL
        ? constants.DASHBOARD_STATUS_LABEL_PENDING_APPROVAL
        : accountStatus === constants.DASHBOARD_STATUS_TITLE_PENDING_ACTIVATION
        ? constants.DASHBOARD_STATUS_LABEL_PENDING_ACTIVATION
        : accountStatus === constants.DASHBOARD_STATUS_TITLE_NOT_APPROVED
        ? constants.DASHBOARD_STATUS_LABEL_NOT_APPROVED
        : accountStatus;
    return status;
  };
  const fetchDataWithLoader = () => {
    setIsLoading(true);
    fetchData(token);
  };

  /* Fetch all the pending organization accounts on page load - for first time only */
  const fetchData = async (token) => {
    if (token) {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}sysadmin/onboard/requests?statuses=${selectedOption?.value}`,
            requestHeader()
          )
          .then((res) => {
            let response = res.data;
            if (response.meta.code === 200) {
              let newOnBoardData = [];
              response.data.forEach((element) => {
                newOnBoardData.push({
                  accountId: element.account.accountId,
                  orgId: element.organization.orgId,
                  name: `${element.account.firstName} ${element.account.lastName} `,
                  email: element.account.email,
                  accountStatus: getStatus(element.account.accountStatus),
                  orgName: element.organization.orgName,
                  orgType:
                    element.organization.orgType === null
                      ? "-"
                      : element.organization.orgType,
                  partnerLevel:
                    element.organization.partnerLevel === null
                      ? "BASIC"
                      : element.organization.partnerLevel,
                  stepPartnerId: element.account.stepPartnerId,
                  salesPersonName: element.account.salesPersonName,
                });
              });
              setOnBoardTableData(newOnBoardData);
              setIsLoading(false);
              setIsSubmitting(null);
            } else {
              setUserMsg(response.data.userMsg);
              setIsLoading(false);
              setIsSubmitting(null);
            }
          });
      } catch (error) {
        setUserMsg(error);
        setIsLoading(true);
        setIsSubmitting(null);
      }
    }
  };

  let data = [];
  /* Add data to the Table */
  if (onBoardTableData !== null) {
    data = onBoardTableData;
  }

  /* Table Columns */
  const columns1 = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Organization",
        accessor: "orgName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "STEP Partner ID",
        accessor: "stepPartnerId",
      },
      {
        Header: "Sales Person",
        accessor: "salesPersonName",
      },
      {
        Header: "org Type",
        accessor: "orgType",
      },
      {
        Header: "Approval Status",
        accessor: "accountStatus",
        Cell: (props) => {
          return (
            <div
              className={`status-container ${props.value
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s+/g, "-")}`}
            >
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Approve",
        accessor: "orgId",
        Cell: (props) => {
          const isSubmittingAccept = `${props.value}-${constants.DASHBOARD_STATUS_ACCEPTED}`;
          const isSubmittingReject = `${props.value}-${constants.DASHBOARD_STATUS_REJECTED}`;
          return (
            <div className="d-flex">
              <div
                className="accept-reject"
                role="button"
                disabled={isSubmitting}
                onClick={() =>
                  handleApprovalSubmit(
                    props.value,
                    constants.DASHBOARD_STATUS_ACCEPTED
                  )
                }
              >
                {isSubmitting === isSubmittingAccept ? (
                  <SiteSpinner height="16px" width="16px"></SiteSpinner>
                ) : (
                  <FaCheckCircle />
                )}
                {constants.DASHBOARD_BUTTON_ACCEPT}
              </div>
              <div
                className="accept-reject"
                role="button"
                disabled={isSubmitting}
                onClick={() =>
                  handleApprovalSubmit(
                    props.value,
                    constants.DASHBOARD_STATUS_REJECTED
                  )
                }
              >
                {isSubmitting === isSubmittingReject ? (
                  <SiteSpinner height="16px" width="16px"></SiteSpinner>
                ) : (
                  <HiOutlineBan />
                )}
                {constants.DASHBOARD_BUTTON_REJECT}
              </div>
            </div>
          );
        },
      },
    ],
    [isSubmitting]
  );
  /* Table Columns */
  const columns2 = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Organization",
        accessor: "orgName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "STEP Partner ID",
        accessor: "stepPartnerId",
      },
      {
        Header: "Sales Person",
        accessor: "salesPersonName",
      },
      {
        Header: "org Type",
        accessor: "orgType",
      },
      {
        Header: "Approval Status",
        accessor: "accountStatus",
        Cell: (props) => {
          return (
            <div
              className={`status-container ${props.value
                ?.toString()
                ?.toLowerCase()
                ?.replace(/\s+/g, "-")}`}
            >
              {props.value}
            </div>
          );
        },
      },
    ],
    []
  );

  /* Handle change of approve checkboxes */
  const handleOnChange = (value) => {
    let newManageApproval = manageApproval;
    if (!newManageApproval.includes(value)) {
      newManageApproval.push(value);
    } else {
      let arrInx = newManageApproval.indexOf(value);
      newManageApproval.splice(arrInx, 1);
    }
    setManageApproval(newManageApproval);
  };

  /* On click submit button - send request to approve the customer accounts API */
  const handleApprovalSubmit = (orgId, status) => {
    setIsSubmitting(`${orgId}-${status}`);
    let requestData = {};
    const found = onBoardTableData.find((s) => s["orgId"] === orgId);

    if (found) {
      requestData = {
        accountId: found.accountId,
        orgId: orgId,
        decision: status,
        partnerLevel: found.partnerLevel,
      };
    }

    if (requestData.orgId) {
      try {
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}sysadmin/onboard/decide`,
            [requestData],
            requestHeader()
          )
          .then((res) => {
            let response = res.data;
            if (response.meta.code === 200) {
              fetchData(token);
            } else {
              setIsSubmitting(null);
              // setUserMsg(response.data.userMsg);
            }
          });
      } catch (error) {
        setUserMsg(error);
        setIsSubmitting(null);
      }
    } else setIsSubmitting(null);
  };

  return (
    <div className="App sign_up">
      <Header />

      <Container fluid>
        <Row className="mb-5">
          <Col
            md={2}
            lg={2}
            xl={2}
            xs={12}
            className="sidebar text-start"
            id="duclo-sidebar"
          >
            <div className="sidebar-content">
              <Sidebar />
            </div>
          </Col>

          <Col md={10} lg={10} xl={10} xs={12}>
            <Container>
              <div className="page-header mt-4 mb-5">
                <div className="page-title">
                  {constants.DASHBOARD_PAGE_TITLE}
                </div>
                <Row xs={1} md={2} className="g-4">
                  <Col md={6} lg={6} xl={6} xs={12} className="text-start">
                    Welcome back,
                    {tokenParsed !== "undefined" ? "" : ""}.
                  </Col>
                  <Col md={6} lg={6} xl={6} xs={12} className="text-end"></Col>
                </Row>
              </div>
            </Container>

            <Container className="h-100">
              <div className="text-center mb-5 mt-5">
                <h2 className="text-uppercase text-danger">
                  {constants.DASHBOARD_TABLE_TITLE}
                </h2>
              </div>
              {!isLoading ? (
                <div className="select-container">
                  {constants.DASHBOARD_STATUS_TITLE}
                  <div className="select-wrapper">
                    <Select
                      closeMenuOnSelect={false}
                      options={options}
                      onChange={(e) => setSelectedOption(e)}
                      value={selectedOption}
                      blurInputOnSelect={true}
                      autoFocus={false}
                      isSearchable={false}
                      styles={{
                        valueContainer: (baseStyles, state) => ({
                          ...baseStyles,
                          justifyContent: "flex-start",
                        }),
                        option: (baseStyles, state) => ({
                          ...baseStyles,
                          display: "flex",
                          justifyContent: "flex-start",
                        }),
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <Row>
                {isLoading ? (
                  <Col>
                    <SiteSpinner height="100px" width="100px"></SiteSpinner>
                  </Col>
                ) : onBoardTableData.length > 0 ? (
                  <div className="table-overflow">
                    <Table
                      columns={
                        selectedOption.value ===
                        constants.DASHBOARD_STATUS_TITLE_PENDING_APPROVAL
                          ? columns1
                          : columns2
                      }
                      data={data}
                    />
                  </div>
                ) : null}
              </Row>
              {/* <Row>
                <Col className="mt-5">
                  <Button
                    className="gray-button"
                    onClick={() => handleApprovalSubmit(this)}
                    disabled={isSubmitting}
                  >
                    Submit
                    {isSubmitting && (
                      <>
                        &nbsp;
                        <Spinner animation="border" size="sm"></Spinner>
                      </>
                    )}
                  </Button>
                </Col>
              </Row> */}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  // We don't want to render all 2000 rows for this example, so cap
  const pageSizeOptions = [10, 20, 30, 40, 50];

  return (
    <>
      <table className="table text-start" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  <span style={{ marginLeft: "5px" }}>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon.SortDown height="15" width="15" />
                      ) : (
                        <Icon.SortUp height="15" width="15" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <Container>
        <Row className="g-4">
          <Col md={6} lg={6} xl={6} xs={6} className="text-start">
            <div>Showing 1 to 10 of {rows.length} entries.</div>
          </Col>
          <Col md={6} lg={6} xl={6} xs={6}>
            <div className="pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>{" "}
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>{" "}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
              <span>
                &nbsp; Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
                &nbsp;
              </span>
              {/*<span>
                  | Go to page:{' '}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                  />
                </span>{' '}
                */}
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
