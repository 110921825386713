const SiteSpinner = (props) => {
    return (
        <div className="site-spinner" style={{backgroundColor: props.backgroundColor, width: props.width, height: props.height}}></div>
    )
}

SiteSpinner.defaultProps = {
    backgroundColor: "#04A89F",
    width: "40px",
    height: "40px"
};

export default SiteSpinner;