import React, { useEffect } from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
/* Import CSS and Image */
import './assets/css/styles.scss';
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.css';
/* Import Keycloack required files */
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./auth/Keycloak";
/* Import required pages */
import SignIn from './auth/SignIn';
import PrivateRoute from './auth/PrivateRoute';
import Dashboard from './pages/Dashboard';
import ServiceFeatures from './pages/features/ServiceFeatures';
import NewFeature from './pages/features/NewFeature';
import EditFeature from './pages/features/EditFeature';
import ServiceProducts from './pages/products/ServiceProducts';
import NewProduct from './pages/products/NewProduct';
import EditProduct from './pages/products/EditProduct';
import { AppDefaults } from './helpers/enums';
import constants from './helpers/en';
import BrandIcon from "./assets/images/duclo_favicon.png"
import megatronIcon from "./assets/images/megatronFavicon.png"
const App = () => {

/* Handle Keycloack Events */
const onEvent = (event, error) => {
  // console.log('onKeycloakEvent', event, error);
  if (typeof error !== undefined) {
    switch (event) {
      case 'onAuthSuccess':
        // console.log('onAuthSuccess');
        localStorage.setItem('authenticated', true);
        break;
      case 'onAuthLogout':
        // console.log('onAuthLogout');
        localStorage.setItem('authenticated', false);
        localStorage.setItem('authToken', null);
        break;
      case 'onTokenExpired':
        // console.log('onTokenExpired');
        localStorage.setItem('authenticated', false);
        localStorage.setItem('authToken', null);
        break;
      default:
        break;
    }
  }
};

useEffect(() => {
  console.log(process.env.REACT_APP_PROJECT );
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      document.title = constants.PROJECT_PAGE_TITLE_MEGATRON;
      let link = document.querySelector("link[rel~='icon']");
      link.href = megatronIcon;
    } else {
      document.title = constants.PROJECT_PAGE_TITLE_DUCLO;
      let link = document.querySelector("link[rel~='icon']");
      link.href = BrandIcon;
    }
  }, []);

return ( 
<ReactKeycloakProvider
  authClient={keycloak}
  // initConfig={{onLoad: 'check-sso', token: localStorage.getItem("authToken")}}
  // initOptions={{onLoad: 'login-required'}}
  onEvent={onEvent}
  onTokens={({ token }) => {
     // console.log('token = ', token);
     localStorage.setItem("authToken", token);
  }}
  >
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/features" element={<PrivateRoute><ServiceFeatures /></PrivateRoute>} />
          <Route path="/newFeatures" element={<PrivateRoute><NewFeature /></PrivateRoute>} />
          <Route path="/editFeatures" element={<PrivateRoute><EditFeature /></PrivateRoute>} />
          <Route path="/products" element={<PrivateRoute><ServiceProducts /></PrivateRoute>} />
          <Route path="/newProducts" element={<PrivateRoute><NewProduct /></PrivateRoute>} />
          <Route path="/editProducts" element={<PrivateRoute><EditProduct /></PrivateRoute>} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ReactKeycloakProvider>)

};

export default App;