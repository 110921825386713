import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from 'react-router';
import {SetAuthToken} from '../auth/SetAuthToken';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
 const { keycloak } = useKeycloak();

 var authenticatedStatus = localStorage.getItem("authenticated");
 var token = localStorage.getItem("authToken");

 /* If token is exists pass this into the header */
 if (token) {
    SetAuthToken(token);
 }

 // const isLoggedIn = keycloak.authenticated;
 const isLoggedIn = authenticatedStatus;
 if( isLoggedIn !== 'undefined' && isLoggedIn !== null ){
   return isLoggedIn ? children : <Navigate to="/dashboard" push={true} />;
 } else{
   localStorage.setItem('authenticated', true);
 }
};

export default PrivateRoute;
