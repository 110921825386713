import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
// import BrandLogo from '../../assets/BrandLogo.png';
import BrandLogo from '../../assets/images/duclo_logo.png';
import MegatronLogo from '../../assets/images/MegatronLogo.svg';
import * as Icon from 'react-bootstrap-icons';
import { useKeycloak } from "@react-keycloak/web";
import { Link } from 'react-router-dom';
import { AppDefaults } from '../../helpers/enums';
import constants from '../../helpers/en';

function Header() {

  const { keycloak } = useKeycloak();

  var authenticatedStatus = localStorage.getItem("authenticated");

  /* On click submit button - send request to approve the customer accounts API */
  const handleDucloLogout = (e) => {
    localStorage.setItem('authenticated', false);
    localStorage.setItem('authToken', null);
    keycloak.logout({redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL});
  }

  return (
      <Navbar id="duclo-top-bar">
        <Container fluid>
            <Row className="w-100 align-items-center">
              <Col md={2} lg={2} xl={2} xs={12}>
                <a className="navbar-brand text-center w-100 duclo_logo" href="/dashboard">
                  <img src={process.env.REACT_APP_PROJECT ===
                            "MEGATRON"
                              ? MegatronLogo
                              : BrandLogo} alt={process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                                ? constants.PROJECT_MEGATRON_TITLE: constants.PROJECT_DUCLO_TITLE} />
                </a>
              </Col>
              <Col md={10} lg={10} xl={10} xs={12}>

              <Navbar className="top-bar-menu" expand="lg">
                {!!authenticatedStatus && (
                  <>
                        <Dropdown align="end">
                          <Dropdown.Toggle>
                            <Icon.PlusCircle height="26" width="26"/>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" href="#">
                                <Icon.Folder2 height="20" width="20" />
                                {' '}New Feature Type
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" href="/newFeatures">
                                <Icon.UiRadiosGrid height="20" width="20" />
                                {' '}New Service Features
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="3" href="#">
                                <Icon.Nut height="20" width="20" />
                                {' '}New Service Product
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    {/*<Nav.Link href="#" >
                      <Icon.Bell height="26" width="26" />
                    </Nav.Link>*/}
                    <Nav.Link href="#" >
                      <Icon.PersonCircle height="26" width="26" />
                    </Nav.Link>
                    <Nav.Link href="#" onClick={() => handleDucloLogout(this)}>
                      <Icon.BoxArrowRight height="26" width="26" />
                    </Nav.Link>
                  </>
                )}


                {/*{!keycloak.authenticated && (
                  <Nav.Link href="#" onClick={() => keycloak.login()}>
                    Login
                  </Nav.Link>
                )}*/}

                </Navbar>
              </Col>
            </Row>
          </Container>
      </Navbar>
  );
}

export default Header;
