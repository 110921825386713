import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as Icon from 'react-bootstrap-icons';

function Sidebar() {

  return (
    <>
    {/* <div className="sidebar-header mt-5">
      <strong>Dashboard</strong>
      </div>*/}
    <Navbar collapseOnSelect expand="lg">

      <Navbar.Toggle aria-controls="duclo-sidebar" className="mt-3 mb-3"/>

      <Navbar.Collapse id="duclo-sidebar" className="mt-3">
        <div className="">

            <Nav className="w-100">
              <Nav.Link href="/dashboard">
                <Icon.House height="26" width="26" /> Dashboard
              </Nav.Link>
            </Nav>

            <Nav className="w-100 text-start">
              <Nav.Link href="#">
                  <Icon.Folder2 height="26" width="26" /> Feature Types
              </Nav.Link>
            </Nav>

            <Nav className="w-100 text-start">
              <Nav.Link href="/features">
                <Icon.UiRadiosGrid height="26" width="26" /> Service Features
              </Nav.Link>
            </Nav>

            <Nav className="w-100 text-start">
              <Nav.Link href="products">
                <Icon.Nut height="26" width="26" /> Service Products
              </Nav.Link>
            </Nav>

            <Nav className="w-100 text-start">
              <Nav.Link href="#">
                <Icon.BoxArrowUpRight height="26" width="26" /> Analytics & Reports
              </Nav.Link>
            </Nav>

            <Nav className="w-100 text-start">
              <Nav.Link href="#">
                <Icon.QuestionCircle height="26" width="26" /> Help
              </Nav.Link>
            </Nav>

        </div>


      </Navbar.Collapse>

    </Navbar>
    </>
  );

}

export default Sidebar;
